.x-curriculum {

    .duration {
        background-color: #37A36C;
        color: white;
    }

    .objective {
        background-color: #C236A6;
        color: white;
    }

    .materials {
        background-color: #0099D2;
        color: white;
    }

    .preparation {
        background-color: #E1A822;
        color: white;
    }

    .plan {
        background-color: #DF7746;
        color: white;
    }

    .instructions {
        background-color: #7030A0;
        color: white;
    }

    .btn-instructions {
        background-color: #7030A0;
        color: white;

        &:hover {
            background-color: #541779;
        }
    }

    .otherResources {
        background-color: #696969;
        color: white;
    }
}

.x-card {
    &.lg {
        min-height: 400px;
    }

    &.md {
        min-height: 340px;
    }
}

.x-btn-edit {
    &:hover {
        color: red;
    }
}

.MuiGrid-container {
    width: 100% !important;
}